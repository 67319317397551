import React from "react";
import { Link } from "react-router-dom";
import { CursorContext } from "../providers/CursorProvider";
import { motion } from "framer-motion";
export function Button({ to, variant, children }) {
  const { textEnter, textLeave } =
    React.useContext(CursorContext);
  return (
    <div className="relative">
      <Link to={to ? to : "#"}>
        <button
          onMouseOver={textEnter}
          onMouseLeave={textLeave}
          className={` text-center rounded-md text-white font-semibold
          ${
            variant === "secondary"
              ? "bg-zinc-900 hover:bg-zinc-800"
              : "bg-emerald-600 hover:bg-emerald-700"
          }
          ${variant === "small" ? "py-2 px-4" : "py-4 px-6"}
          `}
        >
          {children}
        </button>
      </Link>
    </div>
  );
}
