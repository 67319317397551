import React from "react";
import { projects } from "../../data/projects";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { CTA } from "../../components";
import cssImg from "../../assets/logos/css.png";
import htmlImg from "../../assets/logos/html.png";
import jsImg from "../../assets/logos/js.png";
import reactImg from "../../assets/logos/react.png";
import reactNative from "../../assets/logos/reactNative.png";
import nextJs from "../../assets/logos/nextjs.png";
import nodeJs from "../../assets/logos/nodejs.png";
import php from "../../assets/logos/php.png";
import laravel from "../../assets/logos/laravel.png";
import liquid from "../../assets/logos/liquid.png";
import mysql from "../../assets/logos/mysql.png";
import postgresql from "../../assets/logos/postgresql.png";

export function NftGear() {
  const project = projects[0];

  const Header = () => (
    <div className="">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
            {project.name}
          </p>
          <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
            Start building for free, then add a site plan to go live. Account
            plans unlock additional features.
          </p>
        </div>
      </div>
    </div>
  );

  const LogoComponent = () => (
    <div className="">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-1 lg:items-center lg:gap-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Used technologies
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
              Programming languages and frameworks used in this project.
            </p>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-4">
            {/* html */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={htmlImg} alt="html" />
            </div>
            {/* css */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={cssImg} alt="css" />
            </div>
            {/* js */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={jsImg} alt="js" />
            </div>
            {/* react */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={reactImg} alt="react" />
            </div>
            {/* php */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={php} alt="php" />
            </div>
            {/* laravel */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img
                className="max-h-16 pt-4 object-fit"
                src={laravel}
                alt="laravel"
              />
            </div>

            {/* mysql */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={mysql} alt="mysql" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <motion.div initial={{ opacity: 0}} animate={{ opacity: 1 }} exit={{ opacity: 0}}>
      <div className="bg-zinc-900">
        <div aria-hidden="true" className="relative">
          <img
            src={project.headerImg}
            alt=""
            className="h-96 w-full object-cover object-center"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-zinc-900" />
        </div>

        <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {project.name}
            </h2>
            <p className="mt-4 text-gray-500">{project.description}</p>

            <div className="mt-12">
              <Link
                to={project.website}
                className="inline-flex border border-white px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-800"
              >
                Visit site
              </Link>
            </div>
          </div>
        </div>


        <div className="overflow-hidden  py-16 px-4 sm:px-6 lg:px-8 xl:py-36">
          <div className="mx-auto max-w-max lg:max-w-7xl">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="max-w-prose text-base lg:max-w-none">
                <h2 className="font-semibold leading-6 text-zinc-500">
                  Transactions
                </h2>
                <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl">
                  A better way to send money
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="relative md:p-6">
                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                  <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none">
                    <p>
                      Ultrices ultricies a in odio consequat egestas rutrum. Ut
                      vitae aliquam in ipsum. Duis nullam placerat cursus risus
                      ultrices nisi, vitae tellus in. Qui non fugiat aut minus
                      aut rerum. Perspiciatis iusto mollitia iste minima soluta
                      id.
                    </p>
                    <p>
                      Erat pellentesque dictumst ligula porttitor risus eget et
                      eget. Ultricies tellus felis id dignissim eget. Est augue{" "}
                      <a href="#">maecenas</a> risus nulla ultrices congue nunc
                      tortor. Eu leo risus porta integer suspendisse sed sit
                      ligula elit.
                    </p>
                    <ol role="list">
                      <li>
                        Integer varius imperdiet sed interdum felis cras in nec
                        nunc.
                      </li>
                      <li>
                        Quam malesuada odio ut sit egestas. Elementum at porta
                        vitae.
                      </li>
                    </ol>
                    <p>
                      Amet, eu nulla id molestie quis tortor. Auctor erat justo,
                      sed pellentesque scelerisque interdum blandit lectus. Nec
                      viverra amet ac facilisis vestibulum. Vestibulum purus
                      nibh ac ultricies congue.
                    </p>
                  </div>
                  <div className="prose prose-lg prose-indigo mt-6 text-gray-500 lg:mt-0">
                    <p>
                      Erat pellentesque dictumst ligula porttitor risus eget et
                      eget. Ultricies tellus felis id dignissim eget. Est augue
                      maecenas risus nulla ultrices congue nunc tortor.
                    </p>
                    <p>
                      Eu leo risus porta integer suspendisse sed sit ligula
                      elit. Elit egestas lacinia sagittis pellentesque neque
                      dignissim vulputate sodales. Diam sed mauris felis risus,
                      ultricies mauris netus tincidunt. Mauris sit eu ac tellus
                      nibh non eget sed accumsan. Viverra ac sed venenatis
                      pulvinar elit. Cras diam quis tincidunt lectus. Non mi
                      vitae, scelerisque felis nisi, netus amet nisl.
                    </p>
                    <p>
                      Eu eu mauris bibendum scelerisque adipiscing et. Justo,
                      elementum consectetur morbi eros, posuere ipsum tortor.
                      Eget cursus massa sed velit feugiat sed ut. Faucibus eros
                      mauris morbi aliquam nullam. Scelerisque elementum sit
                      magna ullamcorper dignissim pretium.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <LogoComponent />

    <CTA />

        {/* <div className="grid grid-cols-3">
          {project.images.map((image, index) => (
            <div className="relative" key={index}>
              <img
                className="w-full object-fit"
                src={image}
                alt={project.name}
              />
            </div>
          ))}
        </div> */}
      </div>
    </motion.div>
  );
}
