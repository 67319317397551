import React from "react";
import { Button } from "./Button";
import { SocialButtons } from "./SocialButtons";
export function CTA() {
  return (
    <div id="contact" className="bg-zinc-800">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          <span className="block">Can I help your company?</span>
          <span className="block text-emerald-600">
            Let's chat!
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <div className="flex flex-col space-y-4">
            <Button
              to="#"
              className="inline-flex items-center justify-center border border-transparent border-white px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
            >
              Send me an E-mail
            </Button>
            
            {/* divider with text "or get social" */}
            <div className="flex items-center">
                <div className="border-t border-gray-700 flex-grow mr-3"></div>
                <div className="text-gray-500">or get social with me</div>
                <div className="border-t border-gray-700 flex-grow ml-3"></div>
            </div>

            <SocialButtons />
          </div>
        </div>
      </div>
    </div>
  );
}
