import React from "react";
import { Link } from "react-router-dom";
import cssImg from "../assets/logos/css.png";
import htmlImg from "../assets/logos/html.png";
import jsImg from "../assets/logos/js.png";
import reactImg from "../assets/logos/react.png";
import reactNative from "../assets/logos/reactNative.png";
import nextJs from "../assets/logos/nextjs.png";
import nodeJs from "../assets/logos/nodejs.png";
import php from "../assets/logos/php.png";
import laravel from "../assets/logos/laravel.png";
import liquid from "../assets/logos/liquid.png";
import mysql from "../assets/logos/mysql.png";
import postgresql from "../assets/logos/postgresql.png";

export function KnownLanguages() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-1 lg:items-center lg:gap-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              From Front-End to Back-End: <br/><span className="text-emerald-600">A Look at My Tech Stack</span>
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
            Explore the technologies and tools that I use to create robust, scalable, and high-performing websites and applications.
            </p>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-4">
            {/* html */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={htmlImg} alt="html" />
            </div>
            {/* css */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={cssImg} alt="css" />
            </div>
            {/* js */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={jsImg} alt="js" />
            </div>
            {/* react */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={reactImg} alt="react" />
            </div>
            {/* react native */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img
                className="max-h-24 object-fit"
                src={reactNative}
                alt="react native"
              />
            </div>
            {/* next js */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={nextJs} alt="nexjs" />
            </div>
            {/* node js */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={nodeJs} alt="nodejs" />
            </div>
            {/* php */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={php} alt="php" />
            </div>
            {/* laravel */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img
                className="max-h-16 pt-4 object-fit"
                src={laravel}
                alt="laravel"
              />
            </div>
            {/* liquid */}
            <div className="col-span-1 flex justify-center bg-zinc-800 pt-10 pb-6 px-8">
              <img className="max-h-16 object-fit" src={liquid} alt="liquid" />
            </div>
            {/* mysql */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img className="max-h-24 object-fit" src={mysql} alt="mysql" />
            </div>
            {/* postgresql */}
            <div className="col-span-1 flex justify-center bg-zinc-800 py-8 px-8">
              <img
                className="max-h-24 object-fit"
                src={postgresql}
                alt="postgresql"
              />
            </div>

            {/* typescript */}
            {/* supabase */}
            {/* tailwind */}
            {/* bootstrap */}
            {/* figma */}
            {/* adobe cc suite */}
            {/* git */}
            {/* github */}
            {/* heroku */}
            {/* vercel */}
            {/* aws */}
            {/* docker */}
            {/* linux */}
            {/* windows */}
            {/* macos */}
            {/* openAI */}
            {/* rest api */}
            {/* graphql */}



          </div>
        </div>
      </div>
    </div>
  );
}
