import React from "react";
import myImage from "../assets/2021.01.25-5.jpg";

export function AboutMe() {
  return (
    <div className="overflow-hidden py-16 px-4 sm:px-6 lg:px-8 xl:py-36 bg-zinc-800">
      <div className="mx-auto max-w-max lg:max-w-7xl">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="max-w-prose text-base lg:max-w-none">
            <h2 className="font-semibold leading-6 text-zinc-500">From Code to Creativity:</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl">
              A Journey of <span className="text-emerald-600">Passion and Skill</span>
            </p>
          </div>
        </div>
        <div className="relative">
          <div className="relative md:p-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
              <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none space-y-4">
                <p>
                  Welcome to my portfolio site! My name is Niklas Due, and I am
                  a problem solver with a passion for technology and creativity.
                  With my expertise in full stack development, e-commerce,
                  marketing, and content production, I am committed to crafting
                  solutions that help individuals and businesses overcome their
                  biggest challenges.
                </p>

                <p>
                  From creating responsive and engaging websites to developing
                  custom software solutions, I thrive on using my skills and
                  knowledge to help clients turn their ideas into reality.
                  Whether you're looking to improve your online presence,
                  streamline your business processes, or innovate in your
                  industry, I am here to help.
                </p>
              </div>
              <div className="prose prose-lg prose-indigo mt-6 text-gray-500 lg:mt-0 space-y-4">
                <p>
                  At the heart of everything I do is a commitment to
                  collaboration, communication, and creativity. By working
                  closely with my clients, I am able to gain a deep
                  understanding of their needs and objectives, and deliver
                  tailored solutions that exceed their expectations.
                </p>
                <p>
                  So if you're ready to conquer your next challenge and create
                  something great, let's team up and make it happen. Browse my
                  portfolio to see some of my past work, and get in touch to
                  discuss how I can help you achieve your goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
