import React, { createContext } from "react";
import useMouse from "@react-hook/mouse-position";
import { motion, useMotionValue, useSpring } from "framer-motion";

const quotes = [
  {
    quote:
      "The future belongs to those who believe in the beauty of their dreams.",
    author: "Eleanor Roosevelt",
  },
  {
    quote: "You miss 100% of the shots you don't take.",
    author: "Wayne Gretzky",
  },
  {
    quote:
      "Success is not final, failure is not fatal: it is the courage to continue that counts.",
    author: "Winston Churchill",
  },
  {
    quote:
      "In any moment of decision, the best thing you can do is the right thing, the next best thing is the wrong thing, and the worst thing you can do is nothing.",
    author: "Theodore Roosevelt",
  },
  {
    quote:
      "Take the first step in faith. You don't have to see the whole staircase, just take the first step.",
    author: "Martin Luther King Jr.",
  },
  { quote: "Leap and the net will appear.", author: "John Burroughs" },
  { quote: "Just do it.", author: "Nike" },
  { quote: "Fortune favors the bold.", author: "Latin Proverb" },
  {
    quote: "Action is the foundational key to all success.",
    author: "Pablo Picasso",
  },
  {
    quote: "The journey of a thousand miles begins with one step.",
    author: "Lao Tzu",
  },
  {
    quote: "Doubt kills more dreams than failure ever will.",
    author: "Suzy Kassem",
  },
];

//create context
const CursorContext = createContext({});

function CursorProvider({ children }) {
  const [quote, setQuote] = React.useState([]);
  const [cursorVariant, setCursorVariant] = React.useState("default");
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);
  const springConfig = { damping: 25, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const ref = React.useRef(null);

  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  const textEnter = () => {
    setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
    setCursorVariant("text");
  };
  const textLeave = () => setCursorVariant("default");

  React.useEffect(() => {
    const moveCursor = (e) => {
      const offset = calculateOffset(mouse.x, mouse.y, cursorVariant);
  
      cursorX.set(cursorVariant === "text" ? offset.x : offset.x);
      cursorY.set(cursorVariant === "text" ? offset.y : offset.y);
    };
    window.addEventListener("mousemove", moveCursor);
    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  });

  return (
    <CursorContext.Provider value={{ textEnter, textLeave, setCursorVariant }}>
      <div ref={ref}>{children}</div>
      <motion.div
        className={`bg-emerald-600 border-5 border-emerald-700 rounded-full absolute top-0 z-0 flex flex-col justify-center place-items-center ${
          cursorVariant === "text" ? "h-48 w-48 p-2" : "h-4 w-4"
        }`}
        style={{
          translateX: cursorXSpring,
          translateY: cursorYSpring,
        }}
      >
        {cursorVariant === "text" && (
          <>
            <p className="text-xs text-center text-white font-semibold">
              {quote.quote}
              <br />- {quote.author}
            </p>
          </>
        )}
      </motion.div>
    </CursorContext.Provider>
  );
}

export { CursorContext, CursorProvider };

function calculateOffset(cursorX, cursorY, cursorVariant) {
  const offsetAmount = cursorVariant === "text" ? 80 : 20;

  let offsetX = 0;
  let offsetY = cursorY + 20;


  if (cursorX > window.innerWidth / 2) {
    offsetX = cursorX - offsetAmount;
  } else {
    offsetX = cursorX + offsetAmount;
  }


  if(cursorVariant === "text") {
    offsetY = cursorY + 50;
  }

  
  return { x: offsetX, y: offsetY };
}
