import nftgearImage from "../assets/nft-gear-loggedin.png";
import nftgearImage1 from "../assets/nft-gear-1.png";
import nftgearImage2 from "../assets/nft-gear-2.png";

import calendarImage from "../assets/blurredEvents.png";
import smShopImage from "../assets/smShop.png";

export const projects = [
  {
    name: "NFT-GEAR",
    description:
      "A webshop that allows customers to buy phone covers with their own NFTs on them. It uses the Ethereum blockchain and OpenSea API and uses a Laravel backend to generate the print file.",
    image: nftgearImage,
    headerImg: nftgearImage2,
    link: "nft-gear",
    align: "left",
    website: "https://nft-gear.io",
    images: [nftgearImage, nftgearImage1, nftgearImage2],
  },
  {
    name: "Tattoo Calendar",
    description:
      "A calendar app for tattoo artists to keep track of their appointments.",
    image: calendarImage,
    headerImg: "",
    link: "tattoo-calendar",
    align: "right",
  },
  {
    name: "Sorry Mom Shop",
    description:
      "A headless storefront combining 3 different Shopify stores. The frontend is built with React, TailwindCSS and Shopify's Hydrogen framework.",
    image: smShopImage,
    headerImg: "",
    link: "tattoo-calendar",
    align: "left",
  },
];
