import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, NftGear } from "./pages";
import { Layout } from "./components";
import { AnimatePresence, motion } from "framer-motion";
import { ScrollToTop } from "./components";
function App() {
  

  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <AnimatePresence>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="nft-gear" element={<NftGear />} />
              </Route>
            </Routes>
          </AnimatePresence>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
