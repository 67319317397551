import React from "react";
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import { projects } from "../data/projects";
import { Button } from "./Button";
const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
};

export function ProjectSlider({ project }) {
  return (
    <div className="w-full lg:max-w-7xl mx-auto">
      <Flickity
        className={"carousel"} // default ''
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
      >
        {projects.map((project, index) => (
          <div className="flex flex-col mx-8 space-y-4 relative">
            <img
              src={project.image}
              alt=""
              className="w-full h-full object-contain"
            />
            <div className="lg:absolute bottom-0 p-8 bg-zinc-900 bg-opacity-70 w-full space-y-4">
              <h2 className=" text-2xl font-bold leading-8 tracking-tight text-white">
                {project.name}
              </h2>
              <p className="prose text-gray-300 lg:max-w-none space-y-4">
                {project.description}
              </p>
              {/* <div className="flex">
                <Button to={project.link}>Read more</Button>
              </div> */}
            </div>
          </div>
        ))}
      </Flickity>
    </div>
  );
}
