import React, { useRef } from "react";
import { Link } from "react-router-dom";
import {
  CTA,
  SocialCTA,
  SocialButtons,
  AboutMe,
  ProjectPreview,
  ProjectSlider,
  ProjectsSection,
  KnownLanguages,
  Button,
} from "../components";
import { projects } from "../data/projects";
import { AnimatePresence, motion } from "framer-motion";
import myImage from "../assets/2021.01.25-5.jpg";

export function Home() {
  const [isIntroAnimationDone, setIsIntroAnimationDone] = React.useState(false);
  return (
    <div className="relative ">
      

      {/* <HomeContent /> */}
      <IntroAnimation />
      <motion.section
        className="absolute top-0 left-0 w-full h-full bg-zinc-900 "
        initial={{ y: "100vh" }}
        animate={{ y: "0vh" }}
        transition={{
          duration: 0.8,
          delay: 2.8,
          ease: "easeInOut",
          type: "spring",
        }}
      >
        <HomeContent />
      </motion.section>
    </div>
  );
}

function HomeContent() {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="relative"
    >
      <motion.section
        className="relative min-h-screen h-full snap-start"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="grid md:grid-cols-2 ">
          <div className="mx-4 md:m-auto max-w-lg space-y-8">
            <h1 className="text-6xl font-bold tracking-tight text-white whitespace-nowrap lg:flex">
              Hi! I am
              <div className="md:px-0 lg:px-2">
                <span class="relative whitespace-nowrap text-emerald-600">
                  <motion.svg
                    aria-hidden="true"
                    viewBox="0 0 418 42"
                    class="absolute top-2/3 left-0 h-[0.58em] w-full fill-emerald-300/70"
                    preserveAspectRatio="none"
                    initial={{ opacity: 0, scaleY: 0 }}
                    animate={{ opacity: 1, scaleY: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, delay: 3 }}
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                  </motion.svg>
                  <span class="relative">Niklas Due</span>
                </span>
                {/* rotate the hand icon 90 degrees and back to wave */}
                <span className="pl-1">
                  <motion.div
                    className="inline-block"
                    animate={{
                      // scale: [1, 2, 2, 1, 1],
                      rotate: [0, 40, 0, 40, 0],
                      textAnchor: "bottom",
                    }}
                    transition={{
                      delay: 4,
                      duration: 1.5,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.5, 0.8, 1],
                      loop: Infinity,
                      repeatDelay: 1,
                    }}
                  >
                    👋🏻
                  </motion.div>
                </span>
              </div>{" "}
            </h1>
            <p className="max-w-md text-gray-500 text-md md:mt-5 md:max-w-3xl">
              Is your company facing challenges that need solving? If so, I
              would be delighted to assist.
              <br />I am an ambitious and dedicated individual with a passion
              for technology and creativity. With experience in full stack
              development, e-commerce, marketing, and content production, I have
              the skills and knowledge necessary to help your company overcome
              any obstacles you may be facing.
            </p>
            <Button>Let's grab a coffee ☕️</Button>

            {/* social */}
            <div>
              <div className="mb-8 md:mb-0">
                <p className="font-semibold leading-6 text-zinc-500 mb-2">
                  Don't be shy, let's connect on social!
                </p>
                <SocialButtons />
              </div>
            </div>
          </div>
          <div className="order-first md:order-last">
            <img
              className="relative w-[300px] h-[400px] md:w-full md:h-screen object-cover object-top mx-auto md:mx-0 mt-24 md:mt-0 mb-8 lg:mb-0"
              src={myImage}
              alt="me"
            />
          </div>
        </div>
      </motion.section>

      {/* about me */}
      <AboutMe />

      {/* projects */}
      <div className=" py-16 px-4 sm:px-6 lg:px-8 xl:py-36">
        <div className="text-base lg:max-w-none text-center mb-8">
          <h2 className="font-semibold leading-6 text-zinc-500">
            Innovation at Its Finest:
          </h2>
          <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl">
            My Most<span className="text-emerald-600"> Exciting Projects</span>
          </p>
          <p className="mt-4 leading-6 text-zinc-500 lg:max-w-3xl mx-auto">
            From cutting-edge technology to out-of-the-box creativity, take a
            look at some of the projects that have pushed the boundaries of
            what's possible.
          </p>
        </div>
        <ProjectSlider />
      </div>

      {/* social */}
      <SocialCTA />

      {/* languages */}
      <KnownLanguages />

      {/* contact */}
      <CTA />
    </motion.div>
  );
}

function IntroAnimation() {
  const text = "Hi! I am Niklas Due";

  return (
    <div className="flex w-screen h-screen justify-center place-items-center">
      <div className="text-xl sm:text-2xl md:text-3xl lg:text-5xl font-bold tracking-tight text-white flex">
        {/* <TypingWordAnimation word={text} /> */}

        {/* tag */}
        <TypingAnimation />
      </div>
    </div>
  );
}

const TypingAnimation = () => {
  const text = '<Developer name="Niklas Due" />';

  return (
    <>
      {text.split("").map((char, index) => (
        <motion.p
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.01, delay: 0.08 * index }}
          className={`
          ${
            index <= 10 || index >= text.split("").length - 2
              ? "text-emerald-500"
              : index >= 16
              ? "text-amber-700"
              : "text-blue-300"
          }
          ${char === " " && "pl-2 lg:pl-4"}
          `}
        >
          {char}
        </motion.p>
      ))}
    </>
  );
};
